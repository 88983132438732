<template>
  <div class="content">
    <div class="head">
      <van-field
        readonly
        clickable
        name="datetimePicker"
        :value="timeValue"
        label="开始时间"
        placeholder="点击选择时间"
        @click="showTimePicker = true"
      />

      <van-field
        readonly
        clickable
        name="endDatetimePicker"
        :value="endTimeValue"
        label="结束时间"
        placeholder="点击选择时间"
        @click="showEndTimePicker = true"
      />
      <div class="title">
        <span style="flex: 1">设备名称</span>
        <span style="flex: 1">气体浓度(%PPM)</span>
        <span style="flex: 1">机械臂开关值</span>
        <span style="flex: 1">采集时间</span>
      </div>
    </div>
    <van-empty
      style="margin-top: 50px"
      v-if="hasNodata"
      image="error"
      description="暂无实时数据"
    />
    <van-list
      v-else
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="context" v-for="item in list" :key="item.id">
        <span style="flex: 1">{{ item.deviceName }}</span>
        <span style="flex: 1">{{ item.conc }}</span>
        <span style="flex: 1">{{ item.valve==0?'关':'开'}}</span>
        <span style="flex: 1"> {{ item.createAt | formatDate }}</span>
      </div>
    </van-list>

    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        :formatter="formatter"
        :min-date="minStartDate"
        :max-date="maxStartDate"
        type="date"
        @confirm="onTimeConfirm"
        @cancel="showTimePicker = false"
      />
    </van-popup>

    <van-popup v-model="showEndTimePicker" position="bottom">
      <van-datetime-picker
        :formatter="formatter"
        :min-date="minEndDate"
        :max-date="maxEndDate"
        type="date"
        @confirm="onEndTimeConfirm"
        @cancel="showEndTimePicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      list: [],
      loading: false,
      finished: false,
      hasNodata: false,

      timeValue: "",
      netTime:'',
      showTimePicker: false,
      minStartDate: new Date(2022, 0, 1),
      maxStartDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      curMs: 0,
      endTimeValue: "",
      netEndTime:'',
      showEndTimePicker: false,
      minEndDate: new Date(2022, 0, 1),
      maxEndDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      fixMs: 0,
    };
  },
  created() {},
  computed: {},
  filters: {
    formatDate: function (value) {
      if (typeof value == "string" && value.indexOf("T") < 0) {
        value = value.replace(/-/g, "/");
      }
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
  methods: {
    onLoad() {
      this.getList(this.netTime, this.netEndTime);
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },

    onTimeConfirm(time) {
      if (this.compareTime(time, this.netEndTime)) {
        return;
      }
      this.timeValue = this.changeTime(time);
      this.netTime = time;
      this.showTimePicker = false;
      this.page = 1;
      this.getList(this.netTime, this.netEndTime);
    },
    onEndTimeConfirm(time) {
      if (this.compareTime(this.netTime, time)) {
        return;
      }
      this.endTimeValue = this.changeTime(time);
      this.netEndTime = time;
      this.showEndTimePicker = false;
      this.page = 1;
      this.getList(this.netTime, this.netEndTime);
    },
    changeTime(value) {
      if (typeof value == "string" && value.indexOf("T") < 0) {
        value = value.replace(/-/g, "/");
      }
      var d = new Date(value);
      d = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      return d;
    },
    compareTime(st, et) {
      if (typeof st == "string" && st.indexOf("T") < 0) {
        st = st.replace(/-/g, "/");
      }
      if (typeof et == "string" && et.indexOf("T") < 0) {
        et = et.replace(/-/g, "/");
      }
      var s = new Date(st).getTime();
      var d = new Date(et).getTime();
      if (s > d) {
        this.$toast.fail("开始时间不能大于结束时间");
        return true;
      }
      return false;
    },
    getList(timeValue, endTimeValue) {
      if (typeof timeValue == "string" && timeValue.indexOf("T") < 0) {
        timeValue = timeValue.replace(/-/g, "/");
      }
      if (typeof endTimeValue == "string" && endTimeValue.indexOf("T") < 0) {
        endTimeValue = endTimeValue.replace(/-/g, "/");
      }
      this.curMs = !endTimeValue
        ? new Date().getTime()
        : new Date(endTimeValue).getTime();

      var fix3ms = this.curMs - 1000 * 60 * 60 * 24 * 3;
      this.fixMs = new Date(!timeValue ? fix3ms : timeValue).getTime();

      this.http
        .get("/gas/device/historyData", {
          params: {
            companyId: localStorage.getItem("companyId"),
            page: this.page,
            size: 15,
            timeRangeStart: this.fixMs,
            timeRangeEnd: this.curMs,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.loading = false;
            if (this.page == 1) {
              this.list = res.data.data.list.map((item) => {
                return item;
              });
            } else {
              this.list = this.list.concat(res.data.data.list);
            }
            if (!res.data.data.hasNextPage) {
              this.finished = true;
            } else {
              this.page++;
              this.finished = false;
            }
            if (this.page == 1 && this.list.length == 0) {
              this.hasNodata = true;
            } else {
              this.hasNodata = false;
            }
          }
        });
    },
  },

  mounted() {},
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  .head {
    width: 100%;
  }
  .title {
    text-align: center;
    margin: 0 auto;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: blue;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #ffffff;
    font-size: 1.1em;
  }
  .van-list {
    flex: 1;
    overflow: auto;
  }
  .context {
    text-align: center;
    margin: 0 auto;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #333;
    font-size: 1.1em;
  }
}
</style>
